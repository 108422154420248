import React, { useState, useEffect } from 'react';

import Header from '../../componentes/Header';
import './index.css'
import {
    Background,
    Image,
    Forms,
    Services,
    About,
    Footer,
    Linked
} from './styles'



import Main from '../../assets/Background.jpg'
import Manicure from '../../assets/manicure.jpg'
import Maquiagem from '../../assets/imagens/maquiagem.jpeg'
import Cabelos from '../../assets/imagens/cabelo.jpeg'
import Who from '../../assets/me.png'
import Input from '../../componentes/Input';

import { login } from '../../services/api';
import { useHistory } from 'react-router';

const Home = () => {

    const navigation = useHistory();


    const [user, setUser] = useState({});
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');

    const [stateButton, setButton] = useState(false)



    //Basic Y2FpcXVlZHVhcnRlQGhvdG1haWwuY29tOjk4MDc=
    //Basic base64("user:password")

    const Login = (e) => {
        e.preventDefault()
        login(email, pass)
            .then(resp => {
                const { user } = resp.data;
                if (email === '' || pass === '') {
                    setEmail('');
                    setPass('');
                    setError('Preencha os campos vazios')
                }
                if (email === user.email && pass) {
                    localStorage.setItem('user', JSON.stringify(user))
                    navigation.push('/create-client')
                }


            })
            .catch((err) => {
                setError('Email ou senha incorretos')
            })

    }

    return (
        <>
            <div className='cont'>
                <Header className='child' />
                <Background>
                    <Image src={Main} alt="" />
                    <Forms>
                        <div className='title'>Entre na sua conta</div>
                        <input className="input-custom mt-4" type="email" name="" value={email} onChange={e => setEmail(e.target.value)} id="" placeholder="Email" />
                        <input type='password' className="input-custom" name="" value={pass} onChange={e => setPass(e.target.value)} id="" placeholder="Senha" />
                        <button disabled={stateButton} onClick={Login} className='login' type="submit">Entrar</button>

                        <div className='error-msg'>{error}</div>
                        {/* <Linked to='/create-account'>
                            <div className="create-account">Ja possui conta? <span>Cadastra-se</span></div></Linked> */}
                    </Forms>
                </Background>
                <Services className='child'>
                    <h2>Nossos Serviços</h2>
                    <div className="lista">
                        <div className='card'>
                            <img src={Maquiagem} alt="" />
                            <p>Maquiagem</p>
                        </div>
                        <div className='card'>
                            <img src={Cabelos} alt="" />
                            <p>Cabelo</p>
                        </div>
                    </div>
                </Services>
                <About className='child'>
                    <h2 className='text-white'>Sobre</h2>
                    <div className="row">
                        <p className='about-text'>
                            Beauty Care Fans  é um programa de premiação exclusivo do Vitta Beauty Care, um salão de beleza especializado em cabelo e make-up com preços acessíveis e produtos e serviços de extrema qualidade. Seu objetivos é premiar os clientes que consomem nosso serviço e também aqueles nos indicam, através de cupons de descontos e super promoções!
                        </p>
                        <div className="wrapper-img">
                            <img src={Who} alt="" />
                        </div>
                    </div>
                </About>
                <Footer>
                    <p className='directs'>Todos os direitos reservados - © Vitta Beauty Care 2021
                    </p>
                    <p className='directs'>Desenvolvido por Agilis&nbsp;&nbsp;&&nbsp;&nbsp;Matheus A. Silva&nbsp;&nbsp;&&nbsp;&nbsp;Italo de Rezende
                    </p>
                </Footer>
            </div>
        </>
    )
}

export default Home;