import React from 'react';
import { Container, Image, SocialNetworks } from './styles'

import Logo from '../../assets/logo.png'
import FacebookLogo from '../../assets/icons/facebook.png'
import InstagramLogo from '../../assets/icons/instagram.png'
import WhatsAppLogo from '../../assets/icons/whatsapp.png'



const Header = () => {
    return (
        <Container>
            <Image src={Logo} alt="" />
            <SocialNetworks>
                <a className='mr-2' href="https://www.facebook.com/VittaBeautyCare" target="_blank">
                    <img src={FacebookLogo} alt="" />
                </a>
                <a className='mr-2' href="https://www.instagram.com/vitta.beauty.care/?hl=pt-br" target="_blank">
                    <img src={InstagramLogo} alt="" />
                </a>
                <a className='mr-2' href="https://api.whatsapp.com/send?l=pt_BR&phone=5516991462551" target="_blank">
                    <img src={WhatsAppLogo} alt="" />
                </a>
            </SocialNetworks>
        </Container>
    )
}

export default Header;