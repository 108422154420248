import React from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";

import Home from '../pages/Home';
import CadastroCliente from "../pages/CadastroCliente";
import CadastroVenda from '../pages/CadastroVenda';
import ListarCliente from "../pages/ListarCliente";
import Administrativo from '../pages/Administrativo';
import EditarCliente from "../pages/EditarCliente";

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route component={Home} exact path="/" />
            </Switch>
            <Switch>
                <Route component={CadastroCliente} path="/create-client" />
            </Switch>
            <Switch>
                <Route component={CadastroVenda} path="/create-sale" />
            </Switch>
            <Switch>
                <Route component={Administrativo} path="/security-admin" />
            </Switch>
            <Switch>
                <Route component={ListarCliente} path="/list-client" />
            </Switch>
            <Switch>
                <Route component={EditarCliente} path="/edit-client/:id" />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes;