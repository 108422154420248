import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './styles.css';
import logo from '../../assets/logo.png';


const Sidebar = ({ location }) => {
    return (
        <sidebar className='col-2 h-100 d-flex flex-column align-items-center'>
            <img className="img-fluid px-2 py-2" src={logo} alt="" />
            <ul>
                <li>
                    <Link to='/create-client' className={location.pathname === '/create-client' ? 'active' : ''}>
                        <span className="mdi mdi-account-plus-outline mr-2"></span>
                        <text>Criar cliente</text>
                    </Link>
                </li>
                <li>
                    <Link to='/list-client' className={location.pathname === '/list-client' ? 'active' : ''}>
                        <span className="mdi mdi-format-list-text"></span>
                        <text>Listar clientes</text>
                    </Link>
                </li>
                <li>
                    <Link to='/create-sale' className={location.pathname === '/create-sale' ? 'active' : ''}>
                        <span className="mdi mdi-point-of-sale"></span>
                        <text>Cadastrar venda</text>
                    </Link>
                </li>
                <li to="/security-admin" className={location.pathname === '/security-admin' ? 'active' : ''}>
                    <Link to='/security-admin' className={location.pathname === '/security-admin' ? 'active' : ''}>
                        <span className="mdi mdi-account-cog-outline"></span>
                        <text>Administração</text>
                    </Link>
                </li>
            </ul>
        </sidebar>
    )
}


export default withRouter(Sidebar);