import React from 'react';
import { useHistory } from 'react-router';
import Me from '../../assets/icons/default-photo.png';
import './styles.css';






const HeaderMain = () => {
    const navigation = useHistory();
    const user = localStorage.getItem('user');
    const userParser = JSON.parse(user)

    const Logout = () => {
        navigation.push('/')
    }
    return (
        <header className='container-fluid d-flex justify-content-end px-2 py-2'>
            <div className="d-flex align-items-center">
                <span className='text-name d-block mr-3 p-0'>{userParser.name} {userParser.lastName}</span>
                <div className='container-image'>
                    <img src={Me} alt="" />
                </div>
                <div onClick={Logout} className='logout'><h3>Sair</h3></div>
            </div>
        </header>
    )
}

export default HeaderMain;