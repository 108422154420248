import React, { useState, useEffect } from 'react';
import { listClients } from '../../services/api';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import axios from 'axios';
import './styles.css'
import HeaderMain from '../../componentes/Header-main';
import Sidebar from '../../componentes/Sidebar';
import Title from '../../componentes/Title';
import { createClient } from '../../services/api';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateClient = () => {

    const history = useHistory();
    const [clients, setClients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('')


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [balance, setBalance] = useState(0);
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [cpf, setCPF] = useState('');
    const [indicator, setIndicator] = useState('');

    const [selectedClient, setSelectedClient] = useState('')


    useEffect(() => {
        loadList()
    }, [])

    function loadList() {

        listClients().then(response => {
            setClients(response.data.users)
        })

    }


    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (indicator.includes("_")) return toast.error("⠀Número do indicador incompleto")

        const data = {
            name: name,
            lastName: lastName,
            address: address,
            email: email,
            phone: phone,
            cpf: cpf,
            balance: balance,
            indicator: indicator.length > 0 ? indicator : undefined
        }

        data.password = 'vittalure123'
        const s = JSON.stringify(selectedClient)

        
        await toast.promise(
            createClient(data),
            {
                pending: '⠀Carregando...',
                success: '⠀Cliente cadastrado',
                error: '⠀Ops, algo deu errado!'
            }
        );

        setName('');
        setLastName('');
        setCPF('');
        setPhone('');
        setEmail('');
        setBalance(0);
        setIndicator('')
        setAddress('')
    }

    return (
        <>
            <HeaderMain />
            <div className="d-flex flex-row h-100 justify-content-end h-100">
                <Sidebar />
                <div className='offset-md-4 col-10 px-5 py-5'>
                    <Title name='Cadastro de clientes' />
                    <form onSubmit={handleSubmit} className='px-4 mt-4 w-100 d-flex flex-column position-relative' action="">
                        <div className='d-flex flex-row'>
                            <div className="d-flex flex-column left-side">
                                <div className="d-flex flex-row">
                                    <input type="text" value={name} onChange={e => setName(e.target.value)} name="endereço" id="" placeholder='Nome' />
                                    <input className='ml-2' type="text" value={lastName} onChange={e => setLastName(e.target.value)} name="endereço" id="" placeholder='Sobrenome' />
                                </div>
                                <input className='mt-3' type="text" value={email} onChange={e => setEmail(e.target.value)} name="email" id="" placeholder='Email' />
                                <input className='mt-3' type="text" value={address} onChange={e => setAddress(e.target.value)} name="endereço" id="" placeholder='Endereço' />
                                <div className="mt-3 d-flex flex-row">
                                    <InputMask className='input' mask="999.999.999-99" value={cpf} onChange={e => setCPF(e.target.value)} placeholder="CPF" />
                                </div>
                                <div className="mt-3 d-flex flex-row">
                                    <InputMask className='input' mask="(99) 99999-9999" value={phone} onChange={e => setPhone(e.target.value)} placeholder="Telefone" />
                                    <input className='input ml-2' value={balance} onChange={e => setBalance(e.target.value)} placeholder="Crédito" />
                                </div>
                            </div>
                            <div className='right-side ml-4'>
                                <div>
                                    <InputMask className='input' mask="(99) 99999-9999" value={indicator} onChange={e => setIndicator(e.target.value)} placeholder="Quem indicou (Telefone)" />
                                </div>
                            </div>
                        </div>
                        <div className="fixed-bottom d-flex justify-content-end">
                            <button type='submit' className='btn btn-success py-2 mr-4 mb-4 px-5'>Cadastrar</button>
                            <ToastContainer />
                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

export default CreateClient;