import React from 'react';
import PropTypes from 'prop-types'; // ES6


const Input = (type, value, name, placeholder, change, defal) => {
    return (
        <input
            type={type}
            value={value}
            name={name}
            placeholder={placeholder}
            defaultValue={defal}
        />
    )
}



Input.prototype = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
}

export default Input;