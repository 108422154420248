import React, { useState, useEffect } from 'react';


import HeaderMain from '../../componentes/Header-main';
import Sidebar from '../../componentes/Sidebar';
import Title from '../../componentes/Title';
import { listClients, createOrder } from '../../services/api';
import './styles.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CreateSale = () => {
    const [checked, setChecked] = useState(false);
    const [useBalance, setUseBalance] = useState(false);
    const [totalValue, setTotalValue] = useState(0);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState()

    const notify = (msg) => toast.success(msg)
    const notifyerror = (msg) => toast.error(msg)


    async function submitOrder(){
        const data = {
            user: selectedClient,
            totalValue: totalValue,
            useBalance: useBalance,
        }

        await toast.promise(
            createOrder(data),
            {
              pending: '⠀Carregando...',
              success: '⠀Venda cadastrada',
              error: '⠀Ops, algo deu errado!'
            }
        );
    }


    useEffect(() => {
        loadClient()
    }, [])


    function loadClient() {
        listClients().then(client => {
            setClients(client.data.users)
            setSelectedClient(client.data.users[0]._id)
        })
    }

    

    function selectFuntion() {
        return (
            <div className='selected-custom d-flex align-items-center col-8 mr-3'>
                <i className='mdi mdi-star-circle'></i>
                <select onChange={client => setSelectedClient(client.target.value)}>
                    {clients.map(client => {
                        return (
                            <option className='scroll' key={client._id} value={client._id}> {client.name} {client.lastName} | {client.phone}</option>
                        )
                    })}
                </select>
            </div>
        )
    }

    const onChangeSelected = () => {
        setChecked(!checked);
    }

    return (
        <>
            <HeaderMain />
            <div className="d-flex flex-row h-100 justify-content-end">
                <Sidebar />
                <div className='offset-md-4 col-10 px-5 py-5'>
                    <Title name='Cadastrar de Venda' />
                    <div className='d-flex align-items-center mt-3'>
                        {selectFuntion()}
                        <input className='col-4' placeholder='Insira o valor da venda' type="text" onChange={e => setTotalValue(e.target.value)} />

                    </div>
                    
                    <div className='d-flex align-items-center justify-content-end horizontal-line mt-3'>
                        <div className='col-1'>
                            <input type='checkbox' name='check' checked={useBalance} onChange={()=>setUseBalance(!useBalance)} />
                        </div>
                        <div>
                            <h3 className='alert-message align-items-center'>Usar Saldo</h3>
                        </div>
                    </div>

                    <div className='hidden d-none align-items-center justify-content-end horizontal-line mt-3'>
                        <div className='col-1'>
                            <input type='checkbox' name='check' checked={checked} onChange={onChangeSelected} />
                        </div>
                        <div>
                            <h3 className='alert-message align-items-center'>Alerta de SMS</h3>
                        </div>
                    </div>

                    <div className="fixed-bottom d-flex justify-content-end">
                        <button type='submit' className='btn btn-success py-2 mr-4 mb-4 px-5' onClick={()=>{
                            submitOrder()                            
                        }}>Cadastrar</button>
                        <ToastContainer />
                    </div>
                </div>

            </div>
        </>
    )
}


export default CreateSale;


