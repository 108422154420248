import React from 'react';
import './styles.css';



const Title = ({ name }) => {
    return (
        <div className='wrapper-title'>
            <h1>{name}</h1>
            <div className='space mt-2'></div>
        </div>
    )
}

export default Title;