import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeaderMain from '../../componentes/Header-main';
import Sidebar from '../../componentes/Sidebar';
import Title from '../../componentes/Title';
import InputMask from 'react-input-mask';
import './index.css'
import { listClient, updateClient } from '../../services/api';

const EditarCliente = () => {
    const [name, setName] = useState('');
    const [balance, setBalance] = useState(0);
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');

    const [cpf, setCPF] = useState('');
    const [indicator, setIndicator] = useState('');
    const [indicateds, setIndicateds] = useState([]);
    const [email, setEmail] = useState('');
    const { id } = useParams();

    let loading = false;

    useEffect(() => {
        loadClient();
    }, [])


    async function loadClient() {
        const { user } = await listClient(id);
        
        setName(user.name);
        setLastName(user.lastName)
        setAddress(user.address);
        setPhone(user.phone);
        setCPF(user.cpf);
        setBalance(user.balance)
        setEmail(user.email)
        setIndicateds(user.indicateds)
        setIndicator(user.indicator)
    }

    function renderIndicated(indicated) {
        const listItems = [];
        listItems.push(<i className='mdi mdi-star-circle'></i>);
        listItems.push(<div>&nbsp;</div>);
        listItems.push(indicated.name + ' ' + indicated.lastName + ' | ' + indicated.email);
        return (
            <div className='d-flex align-items-center m-1'>
                {listItems}
            </div>
        )
    }

    function renderIndicateds() {
        if(indicateds.length > 0){
            const listItems = [];
            indicateds.map((indicated) => {
                listItems.push(
                    renderIndicated(indicated)
                );
            });
            return (
                <div className='selected-custom mt-3 d-flex flex-column align-items-start'>
                    {listItems}
                </div>
            )
        }else{
            return (<div>
                <div className='selected-custom mt-3 d-flex align-items-center'>
                    <i className='mdi mdi-star-circle'></i>
                    &nbsp;
                    Este cliente não indicou ninguém.
                </div>
            </div>)
        }
            
    }


    function formattedCurrency(value) {
        const format = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
        return format;
    }

    function renderIndicator() {
        return indicator ? (
            <div className='selected-custom mt-3 d-flex align-items-center'>
                <i className='mdi mdi-star-circle'></i>
                &nbsp;
                {indicator.name} {indicator.lastName} | {indicator.email}
            </div>
        ) : (<div>
                <div className='selected-custom mt-3 d-flex align-items-center'>
                    <i className='mdi mdi-star-circle'></i>
                    &nbsp;
                    Ninguém indicou este cliente.
                </div>
            </div>)
    }

    function updateUser() {
        loading = true;
        console.log(loading)

        const data = {
            name: name == '' ? undefined : name,
            lastName: lastName == '' ? undefined : lastName,
            address: address == '' ? undefined : address,
            email: email == '' ? undefined : email,
            phone: phone == '' ? undefined : phone,
            cpf: cpf == '' ? undefined : cpf,
            balance: balance == '' ? undefined : balance,
            // indicator: selectedClient
        }

        console.log(data)

        // updateClient(id, data).then(resp => {
        //     console.log(`Usuário ${id} atualizado!!!`)
        //     loading = false;
        // })
    }
    // returns a loading screen if this.loading is true
    function loadingScreen() {
            return (
                <div className='loading-screen'>
                    <div className='spinner-border text-primary' role='status'>
                        <span className='sr-only'>Carregando...</span>
                    </div>
                </div>
            )
    }

    return loading ? loadingScreen() : (
        <>
            <HeaderMain />

            <div className="d-flex flex-row h-100 justify-content-end h-100">
                <Sidebar />
                <div className='offset-md-4 col-10 px-5 py-5'>
                    <Title name={`Editar cliente ${name}`} />
                    <form className='px-4 mt-4 w-100 d-flex flex-column position-relative'>
                        <div className='d-flex flex-row'>
                            <div className="d-flex flex-column left-side">
                                <div className="d-flex">
                                    <input type="text" value={name} onChange={e => setName(e.target.value)} name="nome" id="" placeholder='Nome' />
                                    <input className='ml-2' type="text" value={lastName} onChange={e => setLastName(e.target.value)} name="last_name" id="" placeholder='Sobrenome' />
                                </div>
                                <input className='mt-3' type="text" value={email} onChange={e => setEmail(e.target.value)} name="email" id="" placeholder='Email' />
                                <input className="mt-3" type="text" value={address} onChange={e => setAddress(e.target.value)} name="endereço" id="" placeholder='Endereço' />
                                <InputMask className='mt-3 input' mask="999.999.999-99" value={cpf} onChange={e => setCPF(e.target.value)} placeholder="CPF" />
                                <div className="d-flex mt-3">
                                    <InputMask className='input' mask="(99) 99999-9999" value={phone} onChange={e => setPhone(e.target.value)} placeholder="Telefone" />
                                    <input disabled='true' className='input ml-2' value={formattedCurrency(balance)} onChange={e => setBalance(e.target.value)} placeholder="Crédito" />
                                </div>

                            </div>
                            <div className='right-side ml-4'>
                                <div hidden={!indicator}>
                                    <h6 className='title'>Cliente indicado por:</h6>
                                    {renderIndicator()}
                                </div>
                                <div hidden={!indicateds.length > 0}>
                                    <h6 className='mt-3 title'>Lista de indicados</h6>
                                    {renderIndicateds()}
                                </div>
                            </div>
                            
                        </div>
                        <div className="fixed-bottom d-flex justify-content-end">
                            <button onClick={()=>updateUser(id)} className='btn btn-success py-2 mr-4 mb-4 px-5'>Atualizar</button>
                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}


export default EditarCliente;