import React, { useState, useEffect, useLayoutEffect } from 'react';
import HeaderMain from '../../componentes/Header-main';
import Sidebar from '../../componentes/Sidebar';
import Title from '../../componentes/Title';
import { setConfig, getAdmin } from '../../services/api';

import { Card } from './styles'

import './styles.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Admin = () => {
    const [checked, setChecked] = useState(false);


    const [indiceMsg, setIndiceMsg] = useState(0);
    const messages = [
        "Olá {{NOME_CLIENTE}}, seja muito bem vindo(a) ao Beauty Care Fans!\
        \nA partir de agora você receberá créditos ao comprar nossos produtos e serviços!\
        \nQuer ganhar ainda mais? Indique suas amigas e ganhe créditos sempre que elas comprarem em nosso salão!\
        \nQuer saber mais? Me chama no WhatsApp (16)99146-2551.",

        "Parabéns {{NOME_CLIENTE}}! \
        \nA partir de agora você faz parte do Beauty Care Fans, vai ganhar créditos sempre que comprar nossos \
        \nprodutos e serviços e ainda mais!!! \
        \nSempre que uma pessoa indicada sua consumir, você também ganha créditos!!! \
        \nQuer saber mais? Me chama no WhatsApp (16)99146-2551.",

        "{{NOME_CLIENTE}} tenho uma novidade pra te contar! \
        \nAgora você faz parte do Beauty Care Fans, um programa exclusivo em que você ganha créditos sempre que \
        \nconsumir algum produto ou serviço em nosso salão! \
        \nE ainda melhor, sempre que uma pessoa indicada sua consumir aqui, você também ganha!!! \
        \nQuer saber mais? Me chama no WhatsApp (16)99146-2551.",

        "Olá {{NOME_CLIENTE}}! \
        \nBem vindo ao Beauty Care Fans. \
        \nSempre que você e as pessoas que indicar ao nosso programa consumirem produtos e serviços em nosso salão, \
        \nvocê ganha créditos que poderá trocar por qualquer serviço que oferecemos!\
        \nQuer saber mais? Me chama no WhatsApp (16)99146-2551."
    ]

    console.log(messages[0])


    const [indiceBuyMsg, setIndiceBuyMsg] = useState(0);
    const buyMessages = [
        "Olá {{NOME_CLIENTE}} \
        \nVocê acaba de ganhar {{CREDITO_RECEBIDO_COMPRA}}. \
        \nCréditos acumulados: \
        \n{{CREDITO_1}} vence em: {{DATA_VENCIMENTO_1}} \
        \n{{CREDITO_2}} vence em: {{DATA_VENCIMENTO_2}} \
        \nTotal em créditos válidos: {{SALDO_CLIENTE}}",

        "Obrigada por prestigiar o Vitta Beauty Care! Você acaba de ganhar {{CREDITO_RECEBIDO_COMPRA}}.  \
        \nCréditos acumulados: \
        \nTotal em créditos válidos: {{SALDO_CLIENTE}}",

        "{{NOME_CLIENTE}}, tenho uma ótima notícia! Você acabada de {{CREDITO_RECEBIDO_COMPRA}}. \
        \nCréditos acumulados: \
        \nTotal em créditos válidos: {{SALDO_CLIENTE}}",

        "{{NOME_CLIENTE}}, acompanhe seus créditos no Beauty Care Fans: \
        \nCréditos recebidos hoje: {{CREDITO_RECEBIDO_COMPRA}} \
        \nCréditos acumulados: \
        \nTotal em créditos válidos: {{SALDO_CLIENTE}}"
    ]

    const [indiceComissionMsg, setIndiceComissionMsg] = useState(0);
    const comissionMessages = [
        "{{NOME_CLIENTE}}, notícia ótima pra você! \
        \nUma indicada sua, inscrita em nosso programa Beauty Care Fans, acaba de gerar {{CREDITO_RECEBIDO_COMISSAO}} para você!\
        \nAproveite! \
        \nCréditos acumulados: \
        \nTotal em créditos válidos: {{SALDO_CLIENTE}}",

        "Olha só que coisa boa, {{NOME_CLIENTE}}. \
        \nUma indicada sua acaba de gerar {{CREDITO_RECEBIDO_COMISSAO}}! \
        \nCréditos acumulados: \
        \nTotal em créditos válidos: {{SALDO_CLIENTE}}",

        "Oi {{NOME_CLIENTE}}… \
        \nVocê acaba de receber créditos no Beauty Care Fans, acompanhe seu saldo e aproveite! \
        \nCréditos acumulados: \
        \nTotal em créditos válidos: {{SALDO_CLIENTE}}",

        "Eba! {{NOME_CLIENTE}}, você acaba de receber {{CREDITO_RECEBIDO_COMISSAO}}, no Beauty Care Fans! \
        \nAcompanhe seus créditos e bora aproveitar! \
        \nCréditos acumulados: \
        \nTotal em créditos válidos: {{SALDO_CLIENTE}}"
    ]

    const [active, setActive] = useState('');

    const [client, setClient] = useState(0);
    const [indicator, setIndicator] = useState(0);

    const [background, setBackground] = useState('#fff');
    const [color, setColor] = useState('');

    const [text, setText] = useState('');

    useLayoutEffect(() => {


    }, [active])


    const changeEffect = () => {

    }


    const onChangeSelected = () => {
        setChecked(!checked);
    }


    const submitOrder = async () => {

        let data = {
            "commissionTax": {
                "indicator": indicator/100,
                "client": client/100
            },
            "messages": {
                "welcome": {
                    "selected": indiceMsg,
                    "options": [
                        messages
                    ]
                },
                "order": {
                    "selected": indiceBuyMsg,
                    "options": [
                        buyMessages
                    ]
                },
                "commission": {
                    "selected": indiceComissionMsg,
                    "options": [
                        comissionMessages
                    ]
                }
            }
        }
        
        
        await toast.promise(
            
            setConfig(data),
            {
              pending: '⠀Carregando...',
              success: '⠀Configurações cadastradas',
              error: '⠀Ops, algo deu errado!'
            }
        );
            
    }

    useEffect(() => {
        getConfig()
    },[])


    async function getConfig() {
        const response = await getAdmin()
        
        setClient(response.company.commissionTax.client * 100)
        setIndicator(response.company.commissionTax.indicator * 100)
        setIndiceMsg(response.company.messages.welcome.selected)
        setIndiceBuyMsg(response.company.messages.order.selected)
        setIndiceComissionMsg(response.company.messages.commission.selected)
    }

    return (
        <>
            <HeaderMain />
            <div className="d-flex flex-row h-100 justify-content-end h-100">
                <Sidebar />
                <div className='offset-md-4 col-10 px-4 py-5'>
                    
                    <Title className='align-self-center' name='Configurações' />
                        <div className='d-flex flex-row'>
                            <div className="d-flex flex-column left-side">

                                <div className='mt-4'>
                                    <h3>Comissões</h3>
                                    <div className="d-flex flex-column mt-3">
                                        <div className="d-flex flex-row justify-center w-25 my-2">
                                            <div class="align-self-center w-50">Indicado: </div>
                                            <input className="align-self-center w-25 mx-1"  value={indicator} onChange={e => setIndicator(e.target.value)}  />
                                            <div class="align-self-center">%</div>
                                        </div>
                                        <div className="d-flex flex-row justify-center w-25">
                                            <div class="align-self-center w-50">Cliente: </div>
                                            <input className="align-self-center w-25 mx-1"  value={client} onChange={e => setClient(e.target.value)}  />
                                            <div class="align-self-center">%</div>
                                        </div>
                                    </div>
                                </div>
                                {/* 
                                <div className='flex align-items-center justify-content-center'>
                                    <input type='checkbox' name='check' checked={checked} onChange={onChangeSelected} />
                                    <h3>Alertar de SMS</h3>
                                </div>
                                */}

                                <div className='mt-4'>
                                    <h3>Mensagem de bem vinda</h3>
                                    <ul className='scroll mt-3'>
                                        {messages.map((m, i) => {

                                            if (indiceMsg == i) {
                                                var colorMsg = '#FF6868'
                                            } else {
                                                colorMsg = ''
                                            }
                                            return (
                                                <>
                                                    <Message indice={i} color={colorMsg} messagesetIndice={setIndiceMsg} text={m} />
                                                </>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className='mt-4'>
                                    <h3>Mensagem de compra</h3>
                                    <ul className='scroll mt-3'>
                                        {buyMessages.map((m, i) => {

                                            if (indiceBuyMsg == i) {
                                                var colorMsg = '#FF6868'
                                            } else {
                                                colorMsg = ''
                                            }
                                            return (
                                                <>
                                                    <Message indice={i} color={colorMsg} messagesetIndice={setIndiceBuyMsg} text={m} />
                                                </>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className='mt-4'>
                                    <h3>Mensagem de comissão</h3>
                                    <ul className='scroll mt-3'>
                                        {comissionMessages.map((m, i) => {

                                            if (indiceComissionMsg == i) {
                                                var colorMsg = '#FF6868'
                                            } else {
                                                colorMsg = ''
                                            }
                                            return (
                                                <>
                                                    <Message indice={i} color={colorMsg} messagesetIndice={setIndiceComissionMsg} text={m} />
                                                </>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className='right-side ml-4'>
                                <div name="" id="" cols="30" rows="10"></div>
                            </div>
                        </div>
                        <div className="fixed-bottom d-flex justify-content-end">
                            <button className='btn btn-success py-2 mr-4 mb-4 px-5' onClick={()=> submitOrder()}>Atualizar</button>
                            <ToastContainer />
                        </div>
                    
                </div>

            </div>
        </>
    )
}


export default Admin;


function Message(props) {
    const { color, indice } = props
    return (
        <Card color={color} onClick={() => props.messagesetIndice(indice)}>
            <pre>
            {props.text}
            </pre>
        </Card >
    )
}