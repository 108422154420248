import React from 'react';
import Routes from './routes';


// import { Container } from './styles';

const App = () => {
  return (
    <Routes />
  )
}

export default App;