import styled from 'styled-components';





export const Container = styled.div`
    height: 110px;
    background-color: var(--primary);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const Image = styled.img`
    height: 140px;
    width: 145px;
`;


export const SocialNetworks = styled.div`
    position: absolute;
    right: 70px;
    top: 30%;
`;