import axios from 'axios'

export const api = axios.create(
    // Choosing environment
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ?
    {
        // Development
        baseURL: 'http://agilis.sa.ngrok.io'
    }:{
        // Production
        baseURL: 'https://beautycare.mybluemix.net'
    }
)


const headers = {
    'Content-Type': 'application/json',
    // 'Authorization': 'Bearer ',
}

//USERS
export const login = async (username, password) => {
    const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

    try {
        const response = await api.get(`/user/auth`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${token}`
            }
        })
        return response
    } catch (error) {
        console.log(error)
    }
}


export const listClient = async (id) => {
    try {
        const response = await api.get(`/user?id=${id}`)
        return response.data
    } catch (error) {
        console.log(error)
    }
}


export const listClients = async () => {
    try {
        const response = await api.get(`/user`)
        return response
    } catch (error) {
        console.log(error)
    }
}

export const createClient = async (data) => { 
    return api.post(`/user`, data) 
}

export const updateClient = async (id, data) => {
    try {
        const response = await api.put(`/user?id=${id}`, data, { headers })
    } catch (error) {
        console.log(error)
    }
}

export const deleteClient = async (id) => {
    const params = {
        id: id,
    }

    try {
        const response = await api.delete(`/user`, { params }, { headers })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

// VENDAS

export const createOrder = async (data) => {
    
    return api.post(`/order`, data)
    
}

// ADMIN

export const getAdmin = async () => {
    const params = {
        id: '61b1393cc091d2790c69a735',
    }
    try {
        const response = await api.get('/company', { params })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const setConfig = async (data) => {
    const params = {
        id: '61b1393cc091d2790c69a735',
    }
    try {
        const response = await api.put('/company', data, {data, params, headers})
        return response.data
    } catch (error) {
        console.log(error)
    }
}
