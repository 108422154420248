import styled from 'styled-components';




export const Card = styled.div`
    background: ${props => props.color};
    margin-top: 6px !important;
    color:  ${props => props.back ? '#fff' : null} ;
    padding: 12px 20px !important;
    font-size: 12px;
    border: 1px solid #ddd;
    border-radius: 10px !important;
    cursor: pointer;
    max-width: 85vh;


`;