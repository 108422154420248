import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HeaderMain from '../../componentes/Header-main';
import Sidebar from '../../componentes/Sidebar';
import Title from '../../componentes/Title';

import { deleteClient, listClients } from '../../services/api'

import './styles.css';



const ListClient = () => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadList()
    }, [])

    function formattedCurrency(value) {
        const format = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
        return format;
    }

    function loadList() {
        setLoading(true)
        listClients().then(response => {
            response.data.users.sort()
            setClients(response.data.users)
        })
        setLoading(false)
    }

    function formattedCurrency(value) {
        const format = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
        return format;
    }

    function deleteUser(id, name, key) {
        if (window.confirm(`Você tem certeza que deseja excluir ${name}?`)) {
            deleteClient(id).then(resp => {
                clients.splice(key, 1)
                window.location.reload()
            })

        }

    }
    return (
        <>
            <HeaderMain />
            <div className="d-flex flex-row h-100 justify-content-end h-100">
                <Sidebar />
                <div className='offset-md-4 col-10 px-5 py-5'>
                    <Title name='Lista de clientes' />
                    <div className="scroll-custom">
                        <table class="table mt-4">
                            <thead class="thead-custom py-4">
                                <tr>
                                    <th scope="col" className='text-center'>Nome</th>
                                    <th scope="col" className='text-center'>Telefone</th>
                                    <th scope="col" className='text-center'>Endereço</th>
                                    <th scope="col" className='text-center'>Saldo</th>
                                    <th scope="col" className='text-center'>Editar/excluir</th>
                                </tr>
                            </thead>
                            <tbody className='mt-2'>
                                {clients.map((user, key) => {
                                    return (
                                        <tr className='py-4 mt-2' key={key}>
                                            <td className='text-center'>{user.name} {user.lastName}</td>
                                            <td className='text-center'>{user.phone}</td>
                                            <td className='text-center'>{user.address === undefined ? 'Sem endereço definido' : user.address}</td>
                                            <td className='text-center'>{formattedCurrency(user.balance)}</td>
                                            <td className='d-flex align-items-center justify-content-center text-right'>
                                                <Link to={`/edit-client/${user._id}`}><button className="btn btn-warning py-0 px-4"><span className='mdi mdi-pencil'></span></button></Link>
                                                <button onClick={() => deleteUser(user._id, user.name, key)} className="btn btn-danger py-0 px-4 ml-2 "><span className=' mdi mdi-delete'></span></button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </>
    )
}


export default ListClient;