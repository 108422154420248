import styled from 'styled-components';
import { Link } from 'react-router-dom';



export const Background = styled.div`
   height: 600px;
   width: 100%;
   background-color: red;
   position: relative;


`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;

`;

export const Linked = styled(Link)`
    text-decoration: none;
    color: #000;
`;

export const Forms = styled.form`
    position: absolute;
    top: 60px;
    right: 20px;
    width: 300px;
    border-radius: 10px;
    background-color: #fff;
    padding: 20px !important;
    display: flex;
    flex-direction: column;
`;

export const Services = styled.div`
    width: 100%;
    background-color: #f8f8f8;
    height: 411px;
    padding: 20px 30px  !important;
`;

export const About = styled.div`
    width: 100%;
    background: linear-gradient(180deg, rgba(255, 245, 5, 0.2) 0%, rgba(255, 245, 5, 0) 100%), #EE8484;
    height: 400px;
    padding: 20px 30px !important;
`;

export const Footer = styled.div`
    height: 80px;
    background-color: #fff;
    width: 100%;
    text-align: center;
    color: var(--primary);
    line-height: 1.5rem;
    padding: 20px 30px  !important;
`;


